<template>

  <div class="px-10">

    <v-toolbar dense flat class="my-5">

      <div class="text-h4 primary--text">การชำระเงิน</div>
      <v-col cols="2" class="ml-10">
          <v-select 
            label="ช่วงเวลา" 
            v-model="timeRangeType" 
            :items="timeRangeTypes" 
            outlined rounded dense hide-details
          ></v-select>
        </v-col>
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"

      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @click:row="edit"
    >

      <template v-slot:[`item._id`]="{ item }">
        {{list.map(function(x) {return x._id; }).indexOf(item._id)+1+(filter.item_per_page*(filter.page-1))}}
      </template>

      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ item.date_of_issue | moment($formatDate) }}
      </template>

      <template v-slot:[`item.product`]="{ item }">
        {{ item.product.map(e => e.name).join(',') }}
      </template>

      <template v-slot:[`item.payment.type`]="{ item }">
        {{ item.payment.type == '0' ? 'รอชำระเงิน' : '' }}
        {{ item.payment.type == '1' ? 'QR CODE' : '' }}
        {{ item.payment.type == '2' ? 'ชำระผ่านบัตรเครดิต' : '' }}
        {{ item.payment.type == '3' ? 'โอนผ่านเลขบัญชี' : '' }}
      </template>

      <!-- <template v-slot:[`item.status`]="{ item }">
        <v-btn
          v-if="item.status == 0"
          small outlined color="error"
          @click="changedStatus(item._id, item.status)"
        >
          จ่ายเงิน
        </v-btn>
      </template>

      <template v-slot:[`item.col_g`]="{ item }">
        <v-btn v-if="item.status == 1" small outlined color="primary">
          พิมพ์
        </v-btn>
        <v-btn v-else color="grey" outlined small>พิมพ์</v-btn>
      </template> -->

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>

    </v-data-table>

  </div>

</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import * as moment from "moment";
export default {
  components: {
    DatePicker,
  },
  data: () => ({
    loading: true,
    pageCount: 0,
    list: [],
    headers: [
      { text: 'ลำดับ', value: '_id' },
      { text: 'วันที่', value: 'date_of_issue' },
      { text: 'รายละเอียดสินค้า', value: 'product' },
      { text: 'ยอดชำระ', value: 'net_price' },
      { text: 'การชำระ', value: 'payment.type' },
      // { text: 'จ่ายเงิน', value: 'status' },
      // { text: 'พิมพ์ใบเสร็จ', value: 'col_g' }
    ],
    timeRangeType: 0,
    timeRangeTypes: [
      { value: 0, text: "3 เดือน"},
      { value: 1, text: "6 เดือน"},
      { value: 2, text: "1 ปี"},
      { value: 3, text: "ทั้งหมด"},
    ],
    filter: {
      item_per_page: 20,
      page: 1,
      start_date: moment().add(-3, "years").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    },
  }),

  created () {
    this.getData();
  },

  methods: {
    edit(item) {
      this.$router.push({name: 'package-payment-detail', params: { id: item._id } });
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {noTimestamp: true}),
      };
      await this.$axios.post(`${this.$baseUrl}/package/get_package`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log("err", err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    changedStatus(id, statusPayment) {
      if (statusPayment == 0) {
        this.$router.push({ name: 'package-payment-pay', params: { id }});
      }
    },
    onChangePage(page){
      this.filter.page = page
      this.getData()
    },
    onChangeitemsPerPage(number){
      this.filter.item_per_page = number
      this.onChangePage(1)
    },
  }
}
</script>